import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import EllisHelmet from '../components/EllisHelmet'
import { formatDatoCMSData } from '../services/datoCMS.service'

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import '../assets/scss/customAwesomeSlider.scss';

export default function Gallery() {
  const currentPage = "gallery";
  const title = 'Gallery';

  const query = useStaticQuery(graphql`
  query galleryQuery {
    allDatoCmsGallery(sort: {fields: position, order: ASC}) {
    nodes {
      caption
      image {
        url(imgixParams: {auto: "format,compress,redeye", fit: "max", w: "2500", h: "1875"})
      }
    }
  }
  }`);

  const galleryData = formatDatoCMSData(query);

  const sliderimages = galleryData.nodes.map((image) => {
    return (<div className="image" data-src={image.image.url}>
      {image.caption && <div>{image.caption}</div>}
    </div>);
  });

  return (
    <Layout className="menuPage" location="gallery">
      <EllisHelmet page={currentPage} title={title} location={null} />
      <div id="gallerypage" className="wrapper style1">
        <div className="container">
          <AwesomeSlider animation="scaleOutAnimation">
            {sliderimages}
          </AwesomeSlider>
        </div>
      </div>
    </Layout>
  );
}
